import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withPrefix } from 'gatsby-link';
import manifest from '@deliveroo/consumer-component-library/manifest.json';
import 'reset-css/reset.css';

import styles from './styles.module.scss';

const LayoutIndex = (props) => (
  <div className={styles.wrapper}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Deliveroo Experience team</title>
      <link
        rel="apple-touch-icon-precomposed"
        href={withPrefix('/favicon/apple-touch-icon.png')}
      />
      <link
        rel="shortcut icon"
        href={withPrefix('/favicon/favicon-32x32.png')}
        type="image/png"
        sizes="32x32"
      />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="stylesheet"
        type="text/css"
        href={manifest['consumer-component-library.css']}
      />
      <link
        rel="preload"
        href={manifest['static/fonts/stratos-semibold.woff']}
      />
    </Helmet>
    {props.children}
  </div>
);

LayoutIndex.propTypes = {
  children: PropTypes.func.isRequired,
};

export default LayoutIndex;
